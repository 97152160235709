import CaseTypeList from '@/components/CaseTypeList';
import QuestionnaireDescription from '@/components/QuestionnaireDescription/QuestionnaireDescription';
import {
  CaseTypePageWrapper,
  CaseTypeWrapper,
  LeftSide,
  RightSide,
} from './styled';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setIsEditMode } from '@/store/slices/ui';
import { useEffect } from 'react';
import { selectSuggestedCaseTypes } from '@/store/slices/caseTypes';
import addSelectedCaseTypesThunk from '@/store/thunks/addSelectedCaseTypes';
import { useRouteChange } from '@/hooks/useRouteChange';
import { QuestionnaireDateTimeDialog } from '@/components/QuestionnaireDateTimeDialog/QuestionnaireDateTimeDialog';
import { CaseClosureDateTimeDialog } from '@/components/CaseClosureDateTimeDialog/CaseClosureDateTimeDialog';
import { useRouter } from 'next/router';
import { selectIsDiagnosisEnabled } from '@/store/slices/questionnaire';
import { Routes } from '@/constants/routes';

const Case = () => {
  const suggestedCaseTypes = useAppSelector(selectSuggestedCaseTypes);
  const dispatch = useAppDispatch();

  const router = useRouter();
  const id = router.query.caseId;
  const isDiagnosisEnabled = useAppSelector(selectIsDiagnosisEnabled);

  useRouteChange(() => {
    suggestedCaseTypes?.length === 1 &&
      dispatch(addSelectedCaseTypesThunk({ caseTypeIds: suggestedCaseTypes }));
  }, suggestedCaseTypes);

  useEffect(() => {
    dispatch(setIsEditMode(false));
  }, [dispatch]);

  useEffect(() => {
    !isDiagnosisEnabled && router.push(`/${Routes.Questionnaire}/${id}`);
  }, [isDiagnosisEnabled, router, id]);

  return (
    <CaseTypePageWrapper>
      <LeftSide>
        <QuestionnaireDescription />
      </LeftSide>
      <RightSide>
        <CaseTypeWrapper>
          <CaseTypeList />
        </CaseTypeWrapper>
      </RightSide>
      <QuestionnaireDateTimeDialog />
      <CaseClosureDateTimeDialog />
    </CaseTypePageWrapper>
  );
};

export default Case;
