import { styled } from '@mui/material';

export const CaseTypePageWrapper = styled('div')`
  display: flex;
  height: 100%;
`;
export const LeftSide = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[50]};
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  overflow-y: auto;
`;
export const RightSide = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[50]};
  flex: 2;
  margin-left: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(2)};
  overflow-y: auto;
`;

export const CaseTypeWrapper = styled('div')`
  width: 100%;
  height: 100%;

  h4 {
    margin-top: 0;
  }
`;

export default CaseTypePageWrapper;
